table.game-stats {
  margin: 0 auto;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 5px;
}

table.game-stats thead th,
table.game-stats tbody td {
  text-align: center;
  width: 30px;
  padding: 3px;
  border: 1px solid #EEE;
}

table.game-stats thead th {
  background-color: #DDD;
}

table.game-stats thead th span {
  position: relative;
  left: 2.5px;
}