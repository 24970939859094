.tile,
.swept-tile,
.swept-mine,
.flagged-tile,
.flagged-swept-tile,
.flagged-swept-mine {
  font-family: monospace;
  font-size: 10px;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border-right: 1px solid #BBB;
  border-bottom: 1px solid #BBB;
  background-color: #EEE;
  display: flex;
  justify-content: center;
  align-items: center;
}

.board.active .tile:active {
  background-color: #CCC;
}

.tile:hover,
.flagged-tile:hover,
.flagged-swept-mine:hover,
.flagged-swept-tile:hover,
.swept-tile:hover,
.swept-mine:hover {
  cursor: default;
}

.swept-tile, .swept-mine {
  background-color: #CCC;
}
.flagged-swept-tile {
  background-color: red;
}

.flagged-swept-mine {
  background-color: green;
}

.swept-tile.m1 {
  color: blue;
}

.swept-tile.m2 {
  color: green;
}

.swept-tile.m3 {
  color: red;
}

.swept-tile.m4 {
  color: navy;
}

.swept-tile.m5 {
  color: maroon;
}

.swept-tile.m6 {
  color: teal;
}

.swept-tile.m7 {
  color: black;
}

.swept-tile.m8 {
  color: #AAA;
}
