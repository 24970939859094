@import url('https://fonts.googleapis.com/css?family=VT323');

* {
  box-sizing: border-box;
}

body {
  font-family: 'VT323', monospace;
  font-size: 13px;
}

header {
  padding: 10px 20px;
  font-size: 1.2rem;
}

h1 {
  text-align: center;
}

.catch-all {
  margin-top: 10rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: monospace;
}