.loader-container h1 {
  margin-top: 5em;
}

.loader-animation-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 5em;
}

.loader-animation-container .smile,
.loader-animation-container .scream,
.loader-animation-container .bomb,
.loader-animation-container .boom {
  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: -25px;
  transform-origin: center;
}

.loader-animation-container .smile {
  animation: smile 8s infinite;
}
.loader-animation-container .scream {
  animation: scream 8s infinite;
}
.loader-animation-container .bomb {
  animation: bomb 8s infinite;
}
.loader-animation-container .boom {
  animation: boom 8s infinite;
}

@keyframes smile {
  0% {
    transform: scale3d(0,0,0);
  }
  12.5% {
    transform: scale3d(1, 1, 1);
  }
  25% {
    transform: scale3d(0,0,0);
  }
  100% {
    transform: scale3d(0,0,0);
  }
}
@keyframes scream {
  0% {
    transform: scale3d(0,0,0);
  }
  25% {
    transform: scale3d(0,0,0);
  }
  37.5% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(0,0,0);
  }
  100% {
    transform: scale3d(0,0,0);
  }
}
@keyframes bomb {
  0% {
    transform: scale3d(0,0,0);
  }
  50% {
    transform: scale3d(0,0,0);
  }
  62.5% {
    transform: scale3d(1, 1, 1);
  }
  75% {
    transform: scale3d(0,0,0);
  }
  100% {
    transform: scale3d(0,0,0);
  }
}
@keyframes boom {
  0% {
    transform: scale3d(0,0,0);
  }
  75% {
    transform: scale3d(0,0,0);
  }
  87.5% {
    transform: scale3d(1, 1, 1);
  }
  100% {
    transform: scale3d(0,0,0);
  }
}